body {
    margin:20px;
}

.awVideoPlayer {
    width:100%;
    height:100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    background-color:#000000;
}

.awVideoPlayer .awVideo {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
}

.awVideoHitBox {
    position: absolute;
    inset: 0;
}

.awOptions {
    display:flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding:0px 16px;
}

.awDuration span {
    font-size:12px;
    color:#FFFFFF;
    font-family:system-ui;
}

.awOptionsBar {
    gap: 12px;
    display:flex;
    flex-direction: row;
    align-items: center;
}

.awOptionsBar.left {
    gap:7px;
}

.awOptionItem {
    width:24px;
    height:24px;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius:999px;
    background-color:rgba(40,40,40,0.75);
}

.awSoundIco {
    width:14px;
    height:14px;
    display: none;
}

.awSoundIco.son {
    display:block;
}

.muted .awSoundIco.son {
    display:none;
}

.muted .awSoundIco.soff {
    display:block;
}

.fullScreenIco {
    width:14px;
    height:14px;
}

.awMain {
    display:flex;
    flex-direction: row;
    align-items: center;
}

.awPlay {
    width:35px;
    height:24px;
    display:flex;
    margin-left: -8px;
    align-items: center;
    justify-content: center;
}

.awPlayIco {
    width:25px;
    height:25px;
    display:none;
}

.paused .awPlayIco.pb {
    display:block;
}

.playing .awPlayIco.sb {
    display:block;
}
.playing .awPlay , .playing .awDuration , .playing .fullScreenAction {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
.playing:hover .awPlay , .playing:hover .awDuration , .playing:hover .fullScreenAction {
    opacity: 1;
}

.trackHitBox {
    flex: 1;
    height:17px;
    display:flex;
    align-items: flex-end;
    justify-content: center;
}

.trackHitBox:hover .awTrackBar {
    height:6px;
}

.awTrackBar {
    flex:1;
    height:3px;
    display:flex;
    flex-direction: row;
    border-radius:0px;
    background-color:rgba(255,255,255,0.5); /* Track Background Color */
    transition: height 0.3s ease;
}
.awTrack {
    width:15%;
    border-top-right-radius: 0px;
    background-color:purple; /* Track Color */
}

.awStatus {
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    width:50px;
    height:50px;
    opacity: 0;
    margin:auto;
    display:flex;
    position: absolute;
    align-items: center;
    border-radius:999px;
    justify-content: center;
    background-color:rgba(23,23,23,0.75);
}

.awStatusIco {
    width:30px;
    height:30px;
    opacity: 0.4;
}

.awControls {
    z-index:1;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.30));
}

.hideDuration .awDuration span {
    display:none;
}

.hidePlay .awPlay {
    display:none;
}

.hideFullScreen .fullScreenAction {
    display:none;
}




/* Fade-out animation */
@keyframes bounce-fade-in {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes bounce-fade-out {
    0% {
        transform: scale(0.8);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}


.paused .awStatus {
    opacity: 1;
    pointer-events: auto;
    animation: bounce-fade-in 0.5s ease-in-out
}

.playing .awStatus {
    opacity: 0;
    animation: bounce-fade-out 0.5s ease-in-out forwards;
}




.awAudioPlayer {
    height:initial;
    border-radius:4px;
    border:1px solid #eeeeee;
    background-color:#f5f5f5;
    justify-content: flex-end;
}
.awAudioPlayer .awDuration span {
    color:#000000;
}
.awAudioPlayer .fullScreenAction {
    display: none;
}
.awAudioPlayer .awControls {
    background-image: none;
}
.awAudioPlayer .awPlay .awPlayIco {
    filter:invert(1);
}
.awAudioPlayer .awStatus {
    display: none;
}
.awAudioPlayer .awOptions {
    padding-top:17px;
}
.awAudioPlayer .soundAction {
    display:none;
}
.awAudioPlayer .awOptionsBar {
    gap: 6px;
}