body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html body #root {
  /* display: flex;
  flex: 1;
  flex-direction: column; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-time-picker {
  width: 100%;
  text-align: center;
}
.react-timerange-picker {
  width: 100%;
  text-align: center;
}

input::placeholder {
  color: var(--placeholder-color, gray);
}

* {
  transition: all 0.1s ease;
}

.map-container * {
  transition: unset;
}

.Toastify__close-button {
  width: unset;
}
